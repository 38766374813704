import React, { useState, useCallback, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import { TextField, Button } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  TicketsContainer,
  TicketsRow,
  Block,
  EditSeveralModal,
  EditSeveralModalTitle,
  CloseEditSeveralModal,
  EditSeveralModalSubtitle,
  Overlay,
} from "./orderupdate";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const OrderUpdate = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const orderId = params.get("id");

  const [dates, setDates] = useState([]);
  const [ticketsNames, setTicketsNames] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [plataforms, setPlataforms] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [userBlocking, setUserBlocking] = useState("");
  const [originalOrderRef, setOriginalOrderRef] = useState("");
  const [recordsToUpdate, setRecordsToUpdate] = useState([]);
  const [
    selectRecordsToUpdateModalOpened,
    setSelectRecordsToUpdateModalOpened,
  ] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const { userName, userUsername, userPermissions } = useContext(StoreContext);

  const [formData, setFormData] = useState({
    orderRef: "",
    orderPlataform: "",
    name: "",
    contact: "",
    whatsapp: "",
    currency: "",
    paymentMethod: "",
    country: "",
    discount: "",
    ticket1Sector: "",
    ticket1Date: "",
    ticket1Price: "",
    ticket1Pax: "",
    ticket1Obs: "",
    ticket1NonPaid: false,
    ticket2Sector: "",
    ticket2Date: "",
    ticket2Price: "",
    ticket2Pax: "",
    ticket2Obs: "",
    ticket2NonPaid: false,
    ticket3Sector: "",
    ticket3Date: "",
    ticket3Price: "",
    ticket3Pax: "",
    ticket3Obs: "",
    ticket3NonPaid: false,
    ticket4Sector: "",
    ticket4Date: "",
    ticket4Price: "",
    ticket4Pax: "",
    ticket4NonPaid: false,
    ticket4Obs: "",
    ticket5Sector: "",
    ticket5Date: "",
    ticket5Price: "",
    ticket5Pax: "",
    ticket5NonPaid: false,
    ticket5Obs: "",
    purchaseDate:""
  });
  const [formsStatus, setFormsStatus] = useState({});

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const handleSelectRecordsToUpdate = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;
      fetch(`${API_URL}getRecordsByOrderRef.php?order-ref=${originalOrderRef}`)
        .then((response) => response.json())
        .then((response) => {
          response = response.filter((r) => r.type !== "tickets");
          if (response.length === 0) onSubmit();
          else {
            setRecordsToUpdate(response);
            setSelectRecordsToUpdateModalOpened(true);
            setFormsStatus(Math.random());
          }
        });
    },
    [originalOrderRef, userPermissions]
  );

  const onSubmit = useCallback(
    (e) => {
      e && e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;

      let body = formData;
      body.lastEditBy = userName;
      body.createdBy = userName;

      let recordsToUpdate = [];

      if (e) {
        const elements = Array.from(e.target.elements);
        elements.forEach((el) => {
          if (el.tagName === "INPUT") {
            const type = el.id.split("-")[0].trim();
            const id = el.id.split("-")[1].trim();
            if (el.checked) {
              recordsToUpdate.push({
                type,
                id,
              });
            }
          }
        });
      }

      body.recordsToUpdate = recordsToUpdate;

      fetch(`${API_URL}tickets/update.php?id=${formData.orderId}`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>Your order has been updated</i>,
              icon: "success",
            }).then(() => {
              return navigate("/order-list");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    },
    [formData, userName, userPermissions, formsStatus]
  );

  useEffect(() => {
    window.onbeforeunload = function () {
      if (userName && userUsername) {
        const body = {
          page: `${location.pathname}${location.search}`,
          username: userUsername,
          name: userName,
        };
        fetch(`${API_URL}locks/unlock.php`, {
          method: "POST",
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((response) => {});
      }
    };
  }, [userName, userUsername, location]);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a, b) => {
          const dateA = a.value.split('/').reverse().join('-');
          const dateB = b.value.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        }).forEach((data) => transformedData.push(data.value));
        setDates(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTicketsNames(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/discounts.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setDiscounts(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/plataforms.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setPlataforms(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/currencies.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setCurrencies(transformedData);
      });

      fetch(`${API_URL}dropdown-data/payment-methods.php`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          let transformedData = [];
          response.forEach((data) => transformedData.push(data.value));
          setPaymentMethods(transformedData);
        });
  }, []);

  useEffect(() => {
    if (!orderId) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Order not found!",
      }).then(() => {
        navigate("/order-list");
      });
    } else {
      fetch(`${API_URL}tickets/list-by-id.php?order_id=${orderId}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          let newData = {};
          response.forEach((ticket, index) => {
            if (index === 0) {
              newData = {
                orderId: ticket.order_id,
                orderRef: ticket.orderRef,
                orderPlataform: ticket.orderPlataform,
                name: ticket.name,
                contact: ticket.contact,
                whatsapp: ticket.whatsapp,
                currency: ticket.currency,
                paymentMethod: ticket.paymentMethod,
                country: ticket.country,
                discount: ticket.discount,
                ticket1Sector: ticket.sector,
                ticket1Date: ticket.date,
                ticket1Price: ticket.price,
                ticket1Pax: ticket.pax,
                ticket1Id: ticket.ticket_id,
                ticket1Obs: ticket.obs,
                ticket1NonPaid: ticket.nonPaid === "1" ? true : false,
                ticket1Canceled: ticket.canceled === "1" ? true : false,
                purchaseDate: ticket.purchaseDate
              };
            } else {
              newData[`ticket${index + 1}Sector`] = ticket.sector;
              newData[`ticket${index + 1}Date`] = ticket.date;
              newData[`ticket${index + 1}Price`] = ticket.price;
              newData[`ticket${index + 1}Pax`] = ticket.pax;
              newData[`ticket${index + 1}Obs`] = ticket.obs;
              newData[`ticket${index + 1}Id`] = ticket.id;
              newData[`ticket${index + 1}NonPaid`] =
                ticket.nonPaid === "1" ? true : false;
              newData[`ticket${index + 1}Canceled`] =
                ticket.canceled === "1" ? true : false;
            }
          });
          setFormData({ ...formData, ...newData });
          setOriginalOrderRef(newData.orderRef);
        });
    }
  }, []);

  return (
    <Main>
      <Sidebar></Sidebar>
      <Content blocked={blocked.toString()}>
        {blocked && (
          <Block>
            This page is temporarily blocked because it is being edited by{" "}
            {userBlocking}{" "}
          </Block>
        )}
        <SubTitle>Order</SubTitle>
        <Title>Update</Title>
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSelectRecordsToUpdate}
          status={formsStatus}
          blocked={selectRecordsToUpdateModalOpened.toString()}
        >
          <FormRow>
            <TextField
              id="order-ref"
              label="#"
              variant="outlined"
              name="orderRef"
              onChange={onchange}
              value={formData.orderRef}
            />
            <FormControl fullWidth>
              <InputLabel id="order-plataform-label">Plataform</InputLabel>
              <Select
                labelId="order-plataform-label"
                id="order-plataform"
                name="orderPlataform"
                label="Plataform"
                value={formData.orderPlataform}
                onChange={onchange}
              >
                {plataforms.map((plataform) => {
                  return (
                    <MenuItem value={plataform} key={plataform}>
                      {plataform}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              name="name"
              onChange={onchange}
              value={formData.name}
            />
            <TextField
              id="contact"
              label="Email"
              variant="outlined"
              name="contact"
              onChange={onchange}
              value={formData.contact}
            />
            <TextField
              id="whatsapp"
              label="Whatsapp"
              variant="outlined"
              name="whatsapp"
              onChange={onchange}
              value={formData.whatsapp}
            />
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                label="Currency"
                name="currency"
                onChange={onchange}
                value={formData.currency}
              >
                {currencies.map((currency) => {
                  return (
                    <MenuItem value={currency} key={currency}>
                      {currency}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="paymentMethod-label">Payment Method</InputLabel>
              <Select
                labelId="paymentMethod-label"
                id="paymentMethod"
                label="Payment Method"
                name="paymentMethod"
                onChange={onchange}
                value={formData.paymentMethod}
              >
                {paymentMethods.map((paymentMethod) => {
                  return (
                    <MenuItem value={paymentMethod} key={paymentMethod}>
                      {paymentMethod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              name="country"
              onChange={onchange}
              value={formData.country}
            />
          </FormRow>
          <FormRow column noGap>
            <InputLabel>Tickets</InputLabel>
            <TicketsContainer>
              <TicketsRow
                canceled={
                  formData.ticket1Canceled &&
                  formData.ticket1Canceled.toString()
                }
              >
                <FormControl fullWidth>
                  <InputLabel id="ticket1-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket1-label"
                    id="ticket1"
                    label="Ticket"
                    name="ticket1Sector"
                    value={formData.ticket1Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date1-label">Date</InputLabel>
                  <Select
                    labelId="date1-label"
                    id="date1"
                    label="Date"
                    name="ticket1Date"
                    value={formData.ticket1Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax1"
                  label="Pax"
                  variant="outlined"
                  name="ticket1Pax"
                  value={formData.ticket1Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price1"
                  label="Price"
                  variant="outlined"
                  name="ticket1Price"
                  value={formData.ticket1Price}
                  onChange={onchange}
                />
                 <TextField
                  id="obs1"
                  label="Obs"
                  variant="outlined"
                  name="ticket1Obs"
                  value={formData.ticket1Obs}
                  onChange={onchange}
                  type="text"
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ticket1NonPaid"
                        name="ticket1NonPaid"
                        onChange={onchange}
                        checked={formData.ticket1NonPaid}
                      />
                    }
                    label="Non Paid"
                  />
                </FormGroup>
                {formData.ticket1Canceled && <h3>Canceled</h3>}
              </TicketsRow>
              <TicketsRow
                canceled={
                  formData.ticket2Canceled &&
                  formData.ticket2Canceled.toString()
                }
              >
                <FormControl fullWidth>
                  <InputLabel id="ticket2-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket2-label"
                    id="ticket2"
                    label="Ticket"
                    name="ticket2Sector"
                    value={formData.ticket2Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date2-label">Date</InputLabel>
                  <Select
                    labelId="date2-label"
                    id="date2"
                    label="Date"
                    name="ticket2Date"
                    value={formData.ticket2Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax2"
                  label="Pax"
                  variant="outlined"
                  name="ticket2Pax"
                  value={formData.ticket2Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price2"
                  label="Price"
                  variant="outlined"
                  name="ticket2Price"
                  value={formData.ticket2Price}
                  onChange={onchange}
                />
                <TextField
                  id="obs2"
                  label="Obs"
                  variant="outlined"
                  name="ticket2Obs"
                  value={formData.ticket2Obs}
                  onChange={onchange}
                  type="text"
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ticket2NonPaid"
                        name="ticket2NonPaid"
                        onChange={onchange}
                        checked={formData.ticket2NonPaid}
                      />
                    }
                    label="Non Paid"
                  />
                </FormGroup>
                {formData.ticket2Canceled && <h3>Canceled</h3>}
              </TicketsRow>
              <TicketsRow
                canceled={
                  formData.ticket3Canceled &&
                  formData.ticket3Canceled.toString()
                }
              >
                <FormControl fullWidth>
                  <InputLabel id="ticket3-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket3-label"
                    id="ticket3"
                    label="Ticket"
                    name="ticket3Sector"
                    value={formData.ticket3Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date3-label">Date</InputLabel>
                  <Select
                    labelId="date3-label"
                    id="date3"
                    label="Date"
                    name="ticket3Date"
                    value={formData.ticket3Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax3"
                  label="Pax"
                  variant="outlined"
                  name="ticket3Pax"
                  value={formData.ticket3Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price3"
                  label="Price"
                  variant="outlined"
                  name="ticket3Price"
                  value={formData.ticket3Price}
                  onChange={onchange}
                />
                <TextField
                  id="obs3"
                  label="Obs"
                  variant="outlined"
                  name="ticket3Obs"
                  value={formData.ticket3Obs}
                  onChange={onchange}
                  type="text"
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ticket3NonPaid"
                        name="ticket3NonPaid"
                        onChange={onchange}
                        checked={formData.ticket3NonPaid}
                      />
                    }
                    label="Non Paid"
                  />
                </FormGroup>
                {formData.ticket3Canceled && <h3>Canceled</h3>}
              </TicketsRow>
              <TicketsRow
                canceled={
                  formData.ticket4Canceled &&
                  formData.ticket4Canceled.toString()
                }
              >
                <FormControl fullWidth>
                  <InputLabel id="ticket4-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket4-label"
                    id="ticket4"
                    label="Ticket"
                    name="ticket4Sector"
                    value={formData.ticket4Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date4-label">Date</InputLabel>
                  <Select
                    labelId="date4-label"
                    id="date4"
                    label="Date"
                    name="ticket4Date"
                    value={formData.ticket4Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax4"
                  label="Pax"
                  variant="outlined"
                  name="ticket4Pax"
                  value={formData.ticket4Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price4"
                  label="Price"
                  variant="outlined"
                  name="ticket4Price"
                  value={formData.ticket4Price}
                  onChange={onchange}
                />
                <TextField
                  id="obs4"
                  label="Obs"
                  variant="outlined"
                  name="ticket4Obs"
                  value={formData.ticket4Obs}
                  onChange={onchange}
                  type="text"
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ticket4NonPaid"
                        name="ticket4NonPaid"
                        onChange={onchange}
                        checked={formData.ticket4NonPaid}
                      />
                    }
                    label="Non Paid"
                  />
                </FormGroup>
                {formData.ticket4Canceled && <h3>Canceled</h3>}
              </TicketsRow>
              <TicketsRow
                canceled={
                  formData.ticket5Canceled &&
                  formData.ticket5Canceled.toString()
                }
              >
                <FormControl fullWidth>
                  <InputLabel id="ticket5-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket5-label"
                    id="ticket5"
                    label="Ticket"
                    name="ticket5Sector"
                    value={formData.ticket5Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date5-label">Date</InputLabel>
                  <Select
                    labelId="date5-label"
                    id="date5"
                    label="Date"
                    name="ticket5Date"
                    value={formData.ticket5Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax5"
                  label="Pax"
                  variant="outlined"
                  name="ticket5Pax"
                  value={formData.ticket5Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price5"
                  label="Price"
                  variant="outlined"
                  name="ticket5Price"
                  value={formData.ticket5Price}
                  onChange={onchange}
                />
                <TextField
                  id="obs5"
                  label="Obs"
                  variant="outlined"
                  name="ticket5Obs"
                  value={formData.ticket5Obs}
                  onChange={onchange}
                  type="text"
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ticket5NonPaid"
                        name="ticket5NonPaid"
                        onChange={onchange}
                        checked={formData.ticket5NonPaid}
                      />
                    }
                    label="Non Paid"
                  />
                </FormGroup>
                {formData.ticket5Canceled && <h3>Canceled</h3>}
              </TicketsRow>
            </TicketsContainer>
          </FormRow>
          <FormRow>
            <FormControl>
              <InputLabel id="discount-label">Discount</InputLabel>
              <Select
                labelId="discount-label"
                id="discount"
                label="Discount"
                name="discount"
                onChange={onchange}
                value={formData.discount}
              >
                {discounts.map((discount) => {
                  return (
                    <MenuItem value={discount} key={discount}>
                      {discount}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="purchaseDate"
              label="Purchase Date"
              variant="outlined"
              name="purchaseDate"
              value={formData.purchaseDate}
              type="date"
              onChange={onchange}
            />
          </FormRow>
          <Button variant="outlined" type="submit">
            Save
          </Button>
        </FormBox>
        <Overlay opened={selectRecordsToUpdateModalOpened.toString()}>
          <EditSeveralModal
            status={formsStatus}
            opened={selectRecordsToUpdateModalOpened.toString()}
          >
            <EditSeveralModalTitle>
              Select others records to update on order-ref {originalOrderRef}
            </EditSeveralModalTitle>
            <EditSeveralModalSubtitle>
              This will update fields "#, Plataform, Name, Email/Whatsapp,
              Country, Currency" in all selected records.
            </EditSeveralModalSubtitle>
            <CloseEditSeveralModal
              onClick={() => setSelectRecordsToUpdateModalOpened(false)}
            />
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
            >
              {recordsToUpdate.map((record) => (
                <FormGroup key={`${record.id}-${record.type}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`${record.type} - ${record.id}`}
                        name={record.id}
                        record-type={record.type}
                        inputProps={{
                          "record-type": record.type,
                        }}
                      />
                    }
                    label={`${record.type} ${
                      record.date ? `- ${record.date}` : ""
                    } ${record.sector ? `- ${record.sector}` : ""}`}
                  />
                </FormGroup>
              ))}
              <Button variant="outlined" type="submit">
                Save
              </Button>
            </FormBox>
          </EditSeveralModal>
        </Overlay>
      </Content>
    </Main>
  );
};

export default OrderUpdate;
