import { Box } from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

const Main = styled.div`
  background: #fcfcfc;
  position: relative;
  width: 100%;
  display: flex;
  min-height: 100vh;
`;

const Content = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  position: relative;
  pointer-events: ${(props) => (props.blocked === "true" ? "none" : "all")};
`;

const Block = styled.div`
  width: 300px;
  height: 300px;
  background: #cccccc95;
  border-radius: 10px;
  border: solid 1px #ccc;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  z-index: 10;
`;

const SubTitle = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #728095;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  display: flex;
  pointer-events: ${(props) => (props.blocked === "true" ? "none" : "normal")};
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  gap: ${(props) => (props.noGap ? "0" : "20px")};
  width: 100%;
  align-items: center;

  & > div {
    width: 100%;
    display: flex;
    gap: 20px;
  }
`;

const TicketsContainer = styled.div`
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 15px;
  width: auto !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TicketsRow = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1.3fr 1fr 1fr 2.5fr 1fr;
  gap: 10px;
  width: 100%;

  background: ${(props) =>
    props.canceled === "true" ? "#f5959550" : "inherit"};
  border-radius: 5px;
  align-items: center;
`;

const TransferGroup = styled.div`
  display: flex;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 5px;
  align-items: center;
`;

const EditSeveralModal = styled.div`
  width: 300px;
  position: fixed;
  display: ${(props) => (props.opened === "true" ? "flex" : "none")};
  color: #545454;
  flex-direction: column;
  background: #fff;
  border: solid 1px #00000050;
  border-radius: 5px;
  z-index: 10;
  padding: 10px;
  top: 200px;
  left: calc(50% - 150px);

  .MuiFormControlLabel-label {
    text-transform: capitalise;
  }

  button {
    width: max-content;
    margin: 0 auto;
    margin-top: 10px;
  }
`;

const EditSeveralModalTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-right: 50px;
`;

const EditSeveralModalSubtitle = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
`;

const CloseEditSeveralModal = styled(CloseIcon)`
  background: #ccc;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const Overlay = styled.div`
  display: ${(props) => (props.opened === "true" ? "flex" : "none")};
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;

export {
  Main,
  SubTitle,
  Content,
  Title,
  FormBox,
  FormRow,
  TicketsContainer,
  TicketsRow,
  TransferGroup,
  Block,
  EditSeveralModal,
  EditSeveralModalTitle,
  EditSeveralModalSubtitle,
  CloseEditSeveralModal,
  Overlay,
};
